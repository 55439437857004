import React from 'react'
import { Carousel } from 'antd';
import { css } from "@emotion/css";
import menuPromotion from "../../../../Assets/Images/text-new-promotion.png";
import CarouselImage1 from "../../../../Assets/Images/Promotion/DNA-1.png"
import CarouselImage2 from "../../../../Assets/Images/Promotion/DNA-2.png"
import CarouselImage3 from "../../../../Assets/Images/Promotion/DNA-3.png"
import CarouselImage4 from "../../../../Assets/Images/Promotion/DNA-4.png"
import CarouselImage5 from "../../../../Assets/Images/Promotion/sl-4.png"
import CarouselImage6 from "../../../../Assets/Images/Promotion/sl-5.png"

const contentStyle = {
    width: "100%",
    textAlign: 'center',
};



function carouselCard() {
    return (
        <>
            <div className="text-Promotion pc">
                <img
                    src={"https://fs.cdnxn.com/landingDna/Images/text-new-promotion.png"}
                    className={css`width:80%;
                    margin:0 auto;
                        }`}
                    alt="..."
                />
            </div>
            <div>
                <Carousel autoplay >
                    <div>
                        <img style={contentStyle} src={"https://fs.cdnxn.com/landingDna/Images/Promotion/DNA-1.png"} alt="..." />
                    </div>
                    <div>
                        <img style={contentStyle} src={"https://fs.cdnxn.com/landingDna/Images/Promotion/DNA-2.png"} alt="..." />
                    </div>
                    <div>
                        <img style={contentStyle} src={"https://fs.cdnxn.com/landingDna/Images/Promotion/DNA-3.png"} alt="..." />
                    </div>
                    <div>
                        <img style={contentStyle} src={"https://fs.cdnxn.com/landingDna/Images/Promotion/DNA-4.png"} alt="..." />
                    </div>
                    <div>
                        <img style={contentStyle} src={"https://fs.cdnxn.com/landingDna/Images/Promotion/sl-4.png"} alt="..." />
                    </div>
                    <div>
                        <img style={contentStyle} src={"https://fs.cdnxn.com/landingDna/Images/Promotion/sl-5.png"} alt="..." />
                    </div>

                </Carousel>
            </div>
        </>

    )
}

export default carouselCard